import React, { Component } from "react";


class OptionsInput extends Component {

	constructor(props){
    	super(props);

    	this.state = {
    		error:null,
    		value:this.props.defaultValue,
    	}

    }

    updateValue(e, itemKey, objectId, callback){
    	callback(e.target.value,itemKey, objectId);
    	this.setState({value:e.target.value});

    }


    render(){
    	let {
            objectId,
            itemKey,
    		title,
    		defaultValue,
    		options,
    		description,
    		updateCallback,
    	} = this.props;


    	let {value} = this.state;

    	let optionList = options.map((item,i,a) => 
    		<option key={i}>{item}</option>
    	)

    	return (

    		<div class="form-group">
	          <label >{title}</label>
	          <select 
	          	onChange={(e) => this.updateValue(e, itemKey, objectId, updateCallback)}  
	          	defaultValue={defaultValue} 
	          	class="form-control" id="exampleSelect1">
	            {optionList}
	          </select>
	        </div>

    	)
    }

}

export default OptionsInput;
