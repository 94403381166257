import React, { Component } from "react";
import Api from '../../api';

class Login extends Component {

	constructor(props) {
		super(props);

		this.state = {
			email:"",
			password:"",
			result: null,
			error:null,
		}

		this.api = new Api(); 

		this.onEmailChange = this.onEmailChange.bind(this);
    	this.onPasswordChange = this.onPasswordChange.bind(this);
    	this.onSubmit = this.onSubmit.bind(this);
	}


	onEmailChange(event){
		this.setState({ email: event.target.value });
	}

	onPasswordChange(event){
		this.setState({ password: event.target.value });
	}

	onSubmit(event){
		//console.log("LOGIN", this.state.email, this.state.password);
		this.api.getAuthToken(this.state.email, this.state.password, function (resp){

			//
		    console.log("response", resp);
		    if (!resp){
		    	console.log("nothing");
		    } else if (resp.status === 200){
		    	console.log("success");
		    	this.api.setAuthToken(resp.data.token, resp.data.expires);
		    	window.location.href = '/'
		    } else if (resp.status === 403){
		    	console.log("invalid creds");
		    } else {
		    	console.log("Error", resp.status);
		    }
		}.bind(this));
		event.preventDefault();
	}


	render (){

		return (
			<div class="container-fluid h-100" style={{height:"100%"}}>


				<div class="row" style={{paddingTop:"20px"}}>
					<div class="col-sm-4">
					</div>
					<div class="col-sm-4">
  				
		  				<form>
						  <fieldset>
						    <legend>Login</legend>
						    <div class="form-group">
						      <label for="exampleInputEmail1">Email address</label>
						      <input onChange={this.onEmailChange} type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"  />
						      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
						    </div>
						    <div class="form-group">
						      <label for="exampleInputPassword1">Password</label>
						      <input onChange={this.onPasswordChange} type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"  />
						    </div>
						    <button onClick={this.onSubmit} type="button" class="btn btn-primary">Submit</button>
						  </fieldset>
						</form>
					</div>
					<div class="col-sm-4">
					</div>  
				</div>				 
  			
  			</div>

		)
	}

}



export default Login;