import { combineReducers } from 'redux'
import * as Actions from './actions'
import {Page, Paginator} from '../utils/paginator'

// reducer with initial state
const initialState = {

  //(handled by account reducer)
  //core api module singleton
  api:null,

  //loaded checks
  init_loaded:false,
  projectsLoaded:false,
  networksForProjectLoaded:false, //boolean (have initial networks been loaded for this project)
  
  //project state 
  accounts:[],
  projectsByAccount:{},
  networksByProject:{}, //id_project --> [1,2,...] //list of network ids
  networksById:{}, //id_network --> <network>
  currentAccount:null,
  currentProject:null,
  currentNetwork:null,
  currentDataset:null,

  //visual notifications
  loading:false,
  error:null,

  
  //all paginators
  networkPaginator:new Paginator(),    //network paginator singleton
  
};

//
//get and manage our current account / project state
function accountReducer(state = initialState, action){
  console.log("accountReducer", action.type, action)
  switch(action.type){
    case "API_INIT_SUCCESS":
      return {
        ...state,
        api:action.api,
      }
    case "INIT":
      return{
        ...state,
        loading:true,
        error:null,
      }
    case "INIT_SUCCESS":
      return {
        ...state,
        init_loaded:true,
      }
    case "REQUEST_ACCOUNTS":
      return {
        ...state,
        loading:true,
        error:null,
      }
    case "REQUEST_ACCOUNTS_SUCCESS":
      return {
        ...state,
        accounts:action.accounts,
        //loading:false,
        error:null,
      }
    case "REQUEST_ACCOUNTS_FAILURE":
      return {
        ...state,
        accounts:[],
        //loading:false,
        error:action.error,
      }
    case "REQUEST_PROJECTS":
      return {
        ...state,
        loading:true,
        error:null,
      }
    case "REQUEST_PROJECTS_SUCCESS":
      let projectsByAccount = state.projectsByAccount;
      projectsByAccount[action.id_account] = action.projects;
      //console.log(projectsByAccount);
      return {
        ...state,
        projectsByAccount:projectsByAccount,
        projectsLoaded:true,
        //loading:false,
        error:null,
      }
    case "REQUEST_PROJECTS_FAILURE":
      return {
        ...state,
        //loading:false,
        error:action.error,
      }
    case "REQUEST_NETWORKS":
      return {
        ...state,
        loading:true,
        error:null,
      }
    case "REQUEST_NETWORKS_SUCCESS":
      console.log("REQUEST_NETWORKS_SUCCESS", action);
      //confirm we have this project in our networksByProject struct
      let networksByProject = state.networksByProject;
      let project_network_ids = [];
      if (networksByProject[action.id_project]){
        project_network_ids = networksByProject[action.id_project];
      }

      //Store networks in dict by id
      let networksById = state.networksById
      for (let i =0; i < action.networks.length; i++){
        let network = action.networks[i];
        networksById[network.id] = network;
        project_network_ids.push(network.id);
      }
      //keep a list of distinct network ids
      networksByProject[action.id_project] = [...new Set(project_network_ids)];
      return {
        ...state,
        networksByProject:networksByProject,
        networksById:networksById,
        //loading:false,
        error:null,
      }
    case "NETWORKS_LOADED":
      return {
        ...state,
        networksForProjectLoaded:true,
      }
    case "REQUEST_NETWORKS_FAILURE":
      return {
        ...state,
        //loading:false,
        error:action.error,
      }
    case "SWITCH_ACCOUNT":
      //persist this account id 
      state.api.persistItem("id_account", action.currentAccount.account_id);
      return {
        ...state,
        currentAccount:action.currentAccount,
      }
    case "SWITCH_PROJECT":
      //persist this project id
      //console.log("SWITCH_PROJECT", action);
      let currentProject = null;
      if (action.project){
        state.api.persistItem("id_project", action.project.id);
        currentProject = action.project;
      } else if (action.id_project){
        state.api.persistItem("id_project", action.id_project);
      }
      return {
        ...state,
        currentProject:currentProject,
        networksForProjectLoaded:false,
      }
    case "SWITCH_NETWORK":
      state.api.persistItem("id_network", action.network.id);
      return {
        ...state,
        currentNetwork:action.network,
      }
    case "SWITCH_DATASET":
      return {
        ...state,
        currentDataset:action.dataset,
      }
    case "REQUEST_LAYER_SCHEMA":
      return {
        ...state,
        loading:true,
        error:null,
      }
    case "REQUEST_LAYER_SCHEMA_SUCCESS":
      return {
        ...state,
        loading:false,
        layerSchema:action.layerSchema,
      }
    case "REQUEST_LAYER_SCHEMA_FAILURE":
      return {
        ...state,
        loading:false,
        error:action.error,
      }

    default:
      return state;
  }
}

function paginationReducer(state = initialState, action){
  let paginator = state.networkPaginator;
  switch(action.type){
  case "SWITCH_PROJECT":
    return{
      ...state,
      //reset our pagination settings
      pagPage:1,          
      pagKey:"by_id",     
      pagOrder:"asc",     
    }
  case "REQUEST_NETWORKS_SUCCESS":
    //add this data to our pagination state
    
    let p = new Page(
      action.page, 
      action.networks,
      action.limit,
      action.total_pages,
      action.has_next,
    );
    paginator.addData(p, action.id_project, action.key, action.page)
    return {
      ...state,
      networkPaginator:paginator,
    }
  case "SWITCH_NETWORKPAG_PAGE":
    paginator.setPage(action.page)
    return {
      ...state,
      networkPaginator:paginator,
    }
  case "SWITCH_NETWORKPAG_KEY":
    paginator.setKey(action.key)
    return {
      ...state,
      networkPaginator:paginator,
    }
  case "SWITCH_NETWORKPAG_ORDER":
    paginator.setKey(action.order)
    return {
      ...state,
      networkPaginator:paginator,
    }
  default:
    return state;
  }
}


const rootReducer = combineReducers({
  paginationReducer,
  accountReducer,
})


export default rootReducer;