import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './bootstrap.css';
import './index.css';

import { connect } from 'react-redux'

//page imports
import Login from './wrappers/login';
import Home from './wrappers/home';
import Projects from './wrappers/projects';
import Navbar from './wrappers/navbar';
import Account from './wrappers/account';
import Train from './wrappers/train';
import AdminDash from './wrappers/admin';
import NetworkDesigner from './wrappers/home/NetworkDesigner';

function AppRouter() {
  
  let defaultView = "loading"

  
  return (
    <Router class="h-100">
      <div>
        <Navbar />
         
        <Route path="/" exact component={() => <Home currentView={defaultView} loadProjects={true} />} />
        <Route path="/network" component={() => <div class="dotted"><NetworkDesigner /> </div>} />
        <Route path="/datasets" component={() => <Home currentView={"datasets"} />} c />
        <Route path="/login" component={Login} />
        <Route path="/account" component={Account} />
        <Route path="/train" component={Train} />
        <Route path="/admin" component={AdminDash} />
      </div>
    </Router>
  );
}



export default AppRouter;