import React, { Component } from "react";


class Content extends Component {


	render (){
	    let {
	      classString,
	      children,
	    } = this.props

	    if (!classString){
	    	classString = "col-sm-9 h-100 dotted h-min"
	    }
			return (
				<div className={classString} style={{padding:"0px"}}>
	        
	        {children}

	      </div>

			)
	}
}

export default Content;