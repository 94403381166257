import React, { Component } from "react";
import { connect } from 'react-redux'
import {Button} from '../../components/button'
import Api from '../../api';
import Footer from '../footer';
import Table from './table';
import PagTable from './PagTable';

class Train extends Component {
	constructor(props){
    	super(props);

	    //check if user is logged in
	    this.api = new Api();
	    if (!this.api.hasToken()){
	      window.location = '/login'
	    }

	    this.state = {
	    	accounts:[],
      		projectsByAccount:{},
      		currentAccount:null,
      		currentProject:null,
	    }

	    this.viewProject = this.viewProject.bind(this);
	    this.viewNetwork = this.viewNetwork.bind(this);
	    this.columnCallback = this.columnCallback.bind(this);
	    this.onNextPage = this.onNextPage.bind(this);
	    this.onPrevPage = this.onPrevPage.bind(this);
	}

	componentDidMount(){
	    console.log(this.props);
	    //initialize this page
	    this.props.onInit();

	}

	shouldComponentUpdate(nextProps, nextState){
		console.log("shouldComponentUpdate", nextProps);
		return true
	}

	viewProject(project){
	    
	    //this.getNetworks(currentAccount.account_id, project.id);
	    //this.setState({currentView:"viewProject", currentProject:project});
	    this.props.onChangeProject(project);
	    this.props.onRequestNetworks();

	}
	

	viewNetwork(item){
		//show the designer page with this network selected
		console.log("viewNetwork", item);
	}

	columnCallback(name){
		console.log("columnCallback", name);
	}

	onNextPage(){
		console.log("ON NEXT PAGE")
		let x = this.props.networkPaginator.nextPage(this.props.currentProject.id)
		console.log(x);

	}

	onPrevPage(){
		this.props.networkPaginator.prevPage(this.props.currentProject.id)
	}


	render (){

		
		
		if (this.props.init_loaded !== true ) {
			return (<div>Loading</div>)
		}

		const {currentAccount, projectsByAccount} = this.props;
		//console.log("train", this.props);


		let projects = [];
		if (currentAccount) {
			//console.log(projectsByAccount, currentAccount)
	        projects = projectsByAccount[currentAccount.account_id];
	    	//console.log(JSON.stringify(projectsByAccount), currentAccount.account_id, projectsByAccount[1], projectsByAccount[currentAccount.account_id]);
	    	
	    }
	    let list = [];
	    if (projects){
	    	list = projects.map(item => 
	            <li onClick={(e) => this.viewProject(item)} key={item.id} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" style={{cursor:"pointer"}}>
	              {item.name}
	              <span class="badge badge-primary badge-pill">{item.num_networks}</span>
	            </li>
	          )
	    }

	   	let networks = []
	   	let totalPages = 0
	   	let currentPage = 0
	   	if (this.props.networksForProjectLoaded && this.props.currentProject){
	   		//console.log("check", Object.keys(this.props.networksByProject), this.props.currentProject.id, this.props.networksByProject[this.props.currentProject.id])
	   		if(this.props.networksByProject[this.props.currentProject.id]){
	   			//networks = this.props.networksByProject[this.props.currentProject.id];
	   			let pag = this.props.networkPaginator.getData(this.props.currentProject.id) 
	   			//console.log("pagination", pag);
	   			if (pag){
	   				networks = pag.items
	   				totalPages = pag.totalPages;
	   				currentPage = pag.page
	   			} 
	   			
	   		} else {
	   			//console.log("no networks");
	   			//no networks found, request networks
	   			this.props.onRequestNetworks();
	   		}
	   	} else {
	   		this.props.onRequestNetworks();
	   	}

	   	//sort by our sortKey (accuracy)
	   	//networks.sort((a, b) => (a.sortKey < b.sortKey) ? 1 : -1)

	   	let breadcrumb = (
	   			<ol class="breadcrumb ">
   		            <li class="breadcrumb-item "><a href="/" class="text-white">Projects</a></li>
		        </ol>)
	   	let content = (<div><h3></h3></div>)

	   	if (this.props.currentProject) {
	   		breadcrumb = (<ol class="breadcrumb ">
            
		            <li class="breadcrumb-item "><a  href="/" class="text-white">Projects</a></li>
		             <li class="breadcrumb-item "><a onClick={(e) => this.viewProject(this.props.currentProject)} href="#" class="text-white">{this.props.currentProject.name}</a></li>
		             
		        </ol>)
	   		content = (<PagTable 
	   						api={this.api}
            				title={this.props.currentProject.name} 
            				items={networks}
            				selectNetworkCallback={this.viewNetwork}
            				columnCallback={this.columnCallback}
            				totalPages={totalPages}
            				currentPage={currentPage}
            				nextPageCallback={this.onNextPage}
            				prevPageCallback={this.onPrevPage}
            			/>)
	   		
	   	} 



		return (
			<div>
			<div class="container-fluid h-100 h-min" >
          <div class="row h-100">
            <div class="col-sm-3 bg-primary border-right-brn h-min" id="leftCol">
              
              <div>


		        
		        <div class="card text-white bg-primary mb-3" >

		        {breadcrumb}

		          <div class="card-header">ALL PROJECTS 
		            
		            
		          </div>
		        </div>

		         <ul class="list-group">

		          {list}

		        </ul>
		      </div>
            </div>
            <div class="col-sm-9 bg-lt">
            	<div class="row h-100">
            		<div class="col-sm-12">
            			
            			{content}
            		</div>
            		
             		
             	</div>
            </div>
            
          </div>
        </div>
        <Footer />     
      </div> 
			
		)
	}
}	

const mapStateToProps = state => {
  console.log("mapStateToProps", Object.keys(state.accountReducer.networksByProject));
  return {
    //accountReducer
    loading: state.accountReducer.loading,
    init_loaded:state.accountReducer.init_loaded,
    projectsLoaded:state.accountReducer.projectsLoaded,
    api: state.accountReducer.api,
    currentAccount: state.accountReducer.currentAccount,
    projectsByAccount: state.accountReducer.projectsByAccount,
    currentProject: state.accountReducer.currentProject,
    networksByProject: state.accountReducer.networksByProject,
    networksForProjectLoaded: state.accountReducer.networksForProjectLoaded,

    //paginationReducer
    networkPaginator:state.paginationReducer.networkPaginator,
    pagPage:state.paginationReducer.pagPage,
  	pagKey:state.paginationReducer.pagKey,
  	pagOrder:state.paginationReducer.pagOrder,
  }
};

const mapDispatchToProps = dispatch => {
  return {
 	onInit: () => dispatch({type:"INIT"}),
 	onRequestNetworks: () => dispatch({type:"REQUEST_NETWORKS"}),
    onChangeAccount: (account) => dispatch({type: "SWITCH_ACCOUNT", account:account}),
    onChangeProject: (x) => dispatch({type: "SWITCH_PROJECT", project:x}),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Train);
