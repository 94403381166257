
import React, { Component } from "react";
import moment from 'moment'
import { connect } from 'react-redux'

import Footer from '../footer';
import {Button} from '../../components/button'
import Api from '../../api';





class Home extends Component {


  constructor(props){
    super(props);
    
    
    let {currentView} = props;
    if (!currentView){
      currentView = "loading"
    }
  
    //check if user is logged in
    this.api = new Api();
    if (!this.api.hasToken()){
      window.location = '/login'
    }

    this.state = {}

  }

  componentDidMount(){

    //console.log("props", this.props);
    //console.log("state", this.state);

    //initialize our app
    this.props.onInit();
    //get our layer schema
    this.props.onLoadLayerSchema();
    
  }

  shouldComponentUpdate(nextProps, nextState){
    //console.log("shouldComponentUpdate");
    //console.log(nextProps);
    // console.log(nextState);
    return true;
  }


	render (){

    const {
    	loading,
    } = this.props

    let context_menu = (<div></div>)
    let content = (<div></div>)
    if (loading){
      content = (
        <div >
          <div class="row">
            <div class="col-sm-3">
            </div>
            <div class="col-sm-3" style={{paddingTop:"100px"}}>
              <h3 class="text-center">LOADING</h3>
              <div class="rotating text-center" style={{fontSize:"40px", width:"40px", margin:"0 auto"}}>
                <i class="fas fa-cog"></i></div>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
        </div>)

    } 
		return (
      <div>
			<div class="container-fluid h-100 h-min" >
          <div class="row h-100">
            <div class="col-sm-3 bg-primary border-right-brn" id="leftCol">
              {context_menu}
            </div>
            
             {content}
            
          </div>
        </div>
        <Footer />     
      </div>  

		)
	}

}

const mapStateToProps = state => {
  //console.log("mapStateToProps", state);
  return {
    //accountReducer
    loading: state.accountReducer.loading,
    api: state.accountReducer.api,
    currentAccount: state.accountReducer.currentAccount,

    //test reducer
    fetching: state.testReducer.fetching,
    dog: state.testReducer.dog,
    error: state.testReducer.dog
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onInit: () => dispatch({type:"INIT"}),
    onInitApi: () => dispatch({type: "API_INIT"}),
    onChangeAccount: (account) => dispatch({type: "SWITCH_ACCOUNT", account:account}),
    onChangeProject: (project) => dispatch({type: "SWITCH_PROJECT", project:project}),
    onChangeNetwork: (network) => dispatch({type: "SWITCH_NETWORK", network:network}),
  	onLoadLayerSchema: () => dispatch({type:"REQUEST_LAYER_SCHEMA"}),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);