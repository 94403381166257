import React, { Component } from "react";


class NumberInput extends Component {

	constructor(props){
    	super(props);

    	this.state = {
    		error:null,
    		value:this.props.defaultValue,
    	}

    }

   updateValue(e, itemKey, objectId, callback){
        
       
        callback(e.target.value, itemKey, objectId);
        this.setState({value:e.target.value});

    }


    render(){
    	let {
    		objectId,
    		itemKey,
    		title,
    		defaultValue,
    		min,
    		max,
    		step,
    		description,
    		updateCallback,
    	} = this.props;

    	let {value} = this.state;
    	
    	return (

    		<div class="form-group">
	          <label >{title} </label>
	          <input onChange={(e) => this.updateValue(e, itemKey, objectId, updateCallback)}  defaultValue={value} type="number" class="form-control" min={min} max={max} step={step} />
	          <small id="" class="form-text text-muted">{description}</small>
	        </div>

    	)
    }

}

export default NumberInput;