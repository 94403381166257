import React, { Component } from "react";


class CreateProject extends Component {


	constructor(props){
    	super(props);

    	this.state = {
    		error:null,
    		values:{
    			"name":null,
    			"num_models":20,
				"max_epochs":10,
				"batch_size":128,
				"generations":1,
				"min_neurons":1,
				"edge_probability":0.2,
				"accuracy_threshold":0.90, 
				"model_type":"dynamic",
				"max_neurons":128, 
				"max_layers":20, 
				"loss":"categorical_crossentropy",// "categorical_crossentropy",
				"metrics":["accuracy"],
				"optimizer":"adadelta",
				"add_activations":true, 
				"mutation_probability":0.2,
				"dataset":"MNIST",
				"network_generator":"dynamic",
				"network_optimizer":"dynamic",
    		},
    		metrics:[
    			//note default is the general "accuracy"
    			{
    				label:"Binary Accuracy",
    				key:"binary_accuracy",
    				checked:false,
    			},
    			{
    				label:"Categorical Accuracy",
    				key:"categorical_accuracy",
    				checked:false,
    			},
    			{
    				label:"Sparse Categorical Accuracy",
    				key:"sparse_categorical_accuracy",
    				checked:false,
    			},
    			{
    				label:"Top K Categorical Accuracy",
    				key:"top_k_categorical_accuracy",
    				checked:false,
    			},
    			{
    				label:"Top K Categorical Accuracy",
    				key:"top_k_categorical_accuracy",
    				checked:false,
    			},
    			{
    				label:"Mean Squared Error",
    				key:"mean_squared_error",
    				checked:false,
    			},
    			{
    				label:"Mean Absolute Error",
    				key:"mean_absolute_error",
    				checked:false,
    			},
    			{
    				label:"Mean Absolute Percentage Error",
    				key:"mean_absolute_percentage_error",
    				checked:false,
    			},
    			{
    				label:"Mean Squared Logarithmic Error",
    				key:"mean_squared_logarithmic_error",
    				checked:false,
    			},
    			{
    				label:"Squared Hinge",
    				key:"squared_hinge",
    				checked:false,
    			},
    			{
    				label:"Hinge",
    				key:"hinge",
    				checked:false,
    			},
    			{
    				label:"Categorical Hinge",
    				key:"categorical_hinge",
    				checked:false,
    			},
    			{
    				label:"Logarithm of the hyperbolic cosine of the prediction error.",
    				key:"logcosh",
    				checked:false,
    			},
    			{
    				label:"Categorical Crossentropy",
    				key:"categorical_crossentropy",
    				checked:false,
    			},
    			{
    				label:"Sparse Categorical Crossentropy",
    				key:"sparse_categorical_crossentropy",
    				checked:false,
    			},
    			{
    				label:"Binary Crossentropy",
    				key:"binary_crossentropy",
    				checked:false,
    			},
    			{
    				label:"Kullback Leibler Crossentropy",
    				key:"kullback_leibler_divergence",
    				checked:false,
    			},
    			{
    				label:"Poisson",
    				key:"poisson",
    				checked:false,
    			},
    			{
    				label:"Cosine Proximity",
    				key:"cosine_proximity",
    				checked:false,
    			}
    		],
    		showAdvanced:false,
    	}

    	this.toggleAdvanced = this.toggleAdvanced.bind(this);
    	this.updateValue = this.updateValue.bind(this);
    	this.updateMetrics = this.updateMetrics.bind(this);
    	this.generateConfig = this.generateConfig.bind(this);

    }

    updateValue(event, key){
    	let {values} = this.state;
    	
    	values[key] = event.target.value;
    	
    	this.setState({values:values})
    }

    updateMetrics(key){
    	
    	let {metrics} = this.state;
    	for (let i = 0; i < metrics.length; i++){
    		let item = metrics[i];
    		if (item.key === key){
    			metrics[i]["checked"] = ((metrics[i]["checked"] === false) ? true : false)
    		}
    	}
    	this.setState({metrics:metrics})
    }

    toggleAdvanced(){
    	const {showAdvanced} = this.state;
    	if (showAdvanced === true){
    		this.setState({showAdvanced:false})
    	} else {
    		this.setState({showAdvanced:true})
    	}
    }

    generateConfig(){
    	const {metrics, values} = this.state;
    	const {onSubmit} = this.props;

    	//check our values are correct
    	if (!values["name"]){
    		this.setState({error:"Project Name is required."});
    		return
    	}

    	//given our values, do a final cleanup and return our state
    	//update metrics
    	let finalMetrics = []
    	for (let i = 0; i < metrics.length; i++){
    		const item = metrics[i];
    		if (item.checked === true){
    			finalMetrics.push(item.key)
    		}
    	}
    	//update defaults, todo: refactor prolific orchard definition to be flat
    	values["defaults"] = {
    		loss:values["loss"],
    		metrics:values["metrics"],
    		optimizer:values["optimizer"],
    	}
    	//callback
    	onSubmit(values)
    }

    renderMetrics(){
    	const {metrics} = this.state;
    	let items = metrics.map(item =>
    		<div class="form-check disabled">
		        <label class="form-check-label">
		          <input onChange={(e) => this.updateMetrics(item.key)} class="form-check-input" type="checkbox" value="" disabled="" />
		         	{item.label}
		        </label>
		      </div>
    	)
    	return (
    		<div>
    		{items}
    		</div>
    	)
    }

    renderAdvanced(){
    	const {values} = this.state;
    	return (
    		<div>
    		<div class="form-group">
    		<label >Max Neurons </label>
	          <input onChange={(e) => this.updateValue(e, "max_neurons")} type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" defaultValue={values["max_neurons"]} min="0" max="1024" step="1" />
	          <small id="" class="form-text text-muted">Maximum number of neurons per layer</small>
	        </div>
	        <div class="form-group">
	          <label >Max layers </label>
	          <input onChange={(e) => this.updateValue(e, "max_layers")}  defaultValue={values["max_layers"]} type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  min="0" max="1024" step="1" />
	          <small id="" class="form-text text-muted">Maximum number of layers per network</small>
	        </div>
	        <div class="form-group">
	          <label >Edge Probability </label>
	          <input onChange={(e) => this.updateValue(e, "edge_probability")}  defaultValue={values["edge_probability"]}  type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  min="0" max="1" step="0.01" />
	          <small id="" class="form-text text-muted">The probability of creating a connection between layers</small>
	        </div>
	        <div class="form-group">
	          <label >Generations </label>
	          <input onChange={(e) => this.updateValue(e, "generations")}  defaultValue={values["generations"]} type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" defaultValue="20" min="1" max="100" />
	          <small id="" class="form-text text-muted">Enter a number between 1 & 100</small>
	        </div>

	        <legend>Validation</legend>
	        <div class="form-group">
	          <label for="exampleSelect1">Loss Function</label>
	          <select onChange={(e) => this.updateValue(e, "loss")}  defaultValue={values["loss"]} class="form-control" id="exampleSelect1">
	            <option>mean_squared_error</option>
	            <option>mean_absolute_error</option>
	            <option>mean_absolute_percentage_error</option>
	            <option>mean_squared_logarithmic_error</option>
	            <option>squared_hinge</option>
	            <option>hinge</option>
	            <option>categorical_hinge</option>
	            <option>logcosh</option>
	            <option>categorical_crossentropy</option>
	            <option>sparse_categorical_crossentropy</option>
	            <option>binary_crossentropy</option>
	            <option>kullback_leibler_divergence</option>
	            <option>poisson</option>
	            <option>cosine_proximity</option>
	          </select>
	        </div>

	        <div class="form-group">
	          <label for="exampleSelect1">Optimizer</label>
	          <select onChange={(e) => this.updateValue(e, "optimizer")}  defaultValue={values["optimizer"]}  class="form-control" id="exampleSelect1">
	          	<option>SGD</option>
	          	<option>RMSprop</option>
	          	<option>adagrad</option>
	          	<option>adadelta</option>
	          	<option>adam</option>
	          	<option>adamax</option>
	            <option>nadam</option>
	          </select>
	        </div>

	        <fieldset class="form-group">
	          <legend>Model Type</legend>
	          <div class="form-check">
	            <label class="form-check-label">
	              <input onChange={(e) => this.updateValue(e, "model_type")}  defaultValue={values["model_type"]} type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1"  />
	              Sequential: models have a single input / output and a single path
	            </label>
	          </div>
	          <div class="form-check">
	          <label class="form-check-label">
	              <input onChange={(e) => this.updateValue(e, "model_type")}  defaultValue={values["model_type"]} type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2"  checked={true}/>
	              Dynamic: models can have multiple inputs / outputs and is a directed acyclic graph
	            </label>
	          </div>
	        </fieldset>

	        <legend>Metrics</legend>
		     {this.renderMetrics()}
		      
		    </div>
		
    	)
    }

	render(){

		const {datasets} = this.props;


		const {showAdvanced, values,error} = this.state;
		let showText = "HIDE"
		let advanced = this.renderAdvanced();
    	if (showAdvanced === false){
    		showText = "SHOW"
    		advanced = (<div></div>)
    	} 

    	let errorMsg = ""
    	if(error){
    		errorMsg = (<div class="alert alert-dismissible alert-danger">
						  <button type="button" class="close" data-dismiss="alert">&times;</button>
						   {error}
						</div>)
    	}

	    return (
        <div class="jumbotron bg-secondary" style={{margin:"20px"}}>
	      <div class="row" style={{paddingBottom:"40px"}}>
	      <div class="col-sm-1" />
	      <div class="col-sm-4">
	        {errorMsg}
	      <fieldset>
	        <h3>Create a New Project</h3>
	        
	        <div class="form-group">
	          <label >Project Name</label>
	          <input onChange={(e) => this.updateValue(e, "name")} type="text" class="form-control" id="" aria-describedby="" placeholder="Enter Name"  />
	        </div>

	        <div class="form-group">
	          <label for="exampleSelect1">Dataset</label>
	          <select onChange={(e) => this.updateValue(e, "dataset")}  defaultValue={values["dataset"]} class="form-control" id="exampleSelect1">
	            <option>CIFAR10</option>
	            <option>CIFAR100</option>
	            <option>IMDB</option>
	            <option>Reuters</option>
	            <option >MNIST</option>
	            <option>Fashion-MNIST</option>
	            <option>Boston housing</option>
	          </select>
	        </div>

	        <div class="form-group">
	          <label >Number of Models </label>
	          <input onChange={(e) => this.updateValue(e, "num_models")} defaultValue={values["num_models"]} type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  min="1" max="100" />
	          <small id="" class="form-text text-muted">Enter a number between 1 & 100</small>
	        </div>
	        <div class="form-group">
	          <label >Max epochs </label>
	          <input onChange={(e) => this.updateValue(e, "max_epochs")} defaultValue={values["max_epochs"]}  type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" defaultValue="20" min="1" max="100" />
	          <small id="" class="form-text text-muted">Enter a number between 1 & 100</small>
	        </div>
	        
	        
	        <div class="form-group">
	          <label >Accuracy Threshold </label>
	          <input onChange={(e) => this.updateValue(e, "accuracy_threshold")} defaultValue={values["accuracy_threshold"]} type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" defaultValue="0.90" min="0" max="1" step="0.01" />
	          <small id="" class="form-text text-muted">What is the threshold accuracy to save a model? eg: 0.90</small>
	        </div>

	        <button onClick={this.generateConfig} type="button" class="btn btn-primary">Create Project</button>
	      </fieldset>
	      
	      </div>
	      <div class="col-sm-4">
	      
	           <button onClick={this.toggleAdvanced} class="btn"><h4>Advanced features </h4>{showText} </button>
	           	{advanced}
	      </div>
	    </div>
    </div>
	      )
  }
}

export default CreateProject;