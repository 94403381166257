import React, { Component } from "react";
import {Page, Paginator} from '../../utils/paginator';
import Api from '../../api';
import { connect } from 'react-redux'
// simpler approach, solve pagination 
// using a custom Component

class PagTable extends Component {

	constructor(props){
		super(props);

		this.state = {
			search:"",
			page:1,
			key:"accuracy",
			order:"desc",
			filters:{"above_accuracy":0.0},
			totalPages:2,
			paginator:new Paginator(),
			allowedKeys:{"id":true, "accuracy":true},
		}

		this.nextPage = this.nextPage.bind(this);
		this.prevPage = this.prevPage.bind(this);
		this.columnCallback = this.columnCallback.bind(this);
		this.updateSearch = this.updateSearch.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.viewNetwork = this.viewNetwork.bind(this);
		this.fetchPage = this.fetchPage.bind(this);
		this.flipOrder = this.flipOrder.bind(this);
	}

	componentDidMount(){
		this.fetchPage(1);
	}

	updateSearch(event){
		this.setState({search:event.target.value});
	}

	clearSearch(){
		this.setState({search:""});
	}

	viewNetwork(item){
		if (this.props.selectNetworkCallback){
			this.props.selectNetworkCallback(item);
		}
	}

	flipOrder(){
		let {order} = this.state;
		if (order === "asc"){
			return "desc"
		}
		return "asc"
	}

	columnCallback(colname){
		
		colname = colname.trim()
		console.log("columnCallback", colname);

		if (this.state.allowedKeys[colname]){
			if (this.state.key === colname){
				//flip order if we're clicking the same col twice
				this.setState({...this.state, order:this.flipOrder()});
			} else {
				//switch key by colum
				this.setState({...this.state, key:colname});
			}
		} else {
			console.log("Sorting by ", colname, " not implemented")
		}

	}

	nextPage(e){
		console.log("nextPage");
		const page = this.state.page + 1; 
		if (page > this.state.totalPages){
			return
		}
		this.setState({...this.state, page:page});
		if(!this.state.paginator.hasPage(this.props.currentProject.id, this.state.key, page)){
			//fetch the page, then switch
			this.fetchPage(page);
		}
	}

	prevPage(e){
		console.log("prevPage");
		const page = this.state.page - 1; 
		if (page === 0){
			return
		}
		this.setState({...this.state, page:page});
		if(!this.state.paginator.hasPage(this.props.currentProject.id, this.state.key, page)){
			//fetch the page, then switch
			this.fetchPage(page);
		}
	}

	fetchPage(pageNum){
		console.log("fetch", pageNum);

		this.props.api.getNetworksPaginated(this.props.currentAccount.account_id, this.props.currentProject.id, pageNum, this.state.key, this.state.order, this.state.filters, function(resp){
			console.log("get_neworks", resp);
			if (!resp){
            	console.log("nothing");
        	} else if (resp.status === 200) {
        		const items = resp.data.networks.map(item =>
        			item.id,
        		)
				const p = new Page(pageNum, items, resp.data.limit, resp.data.total_pages, resp.data.has_next);
				const {paginator} = this.state;
				paginator.addData(p, this.props.currentProject.id, this.state.key, pageNum);
				this.props.onNetworksSuccess(resp.data.networks);
				this.setState({...this.state, paginator:paginator, totalPages:resp.data.total_pages});

			} else {
          		console.log("error", resp.status, resp.data);
        	}
		}.bind(this));

	}

	render(){


		let {title, currentPage, totalPages} = this.props;
		const {search} = this.state;

		let list = [];

		
		let cancelBtn = (<div></div>);
	    if (search.length > 0) {
	      items = items.filter(function(item){
	      	let name = item.name ? item.name : "Network "+item.id
	        return name.toLowerCase().includes(search.toLowerCase())
	      })
	      cancelBtn = (<i onClick={this.clearSearch} class="fas fa-times-circle"></i>)
	    }

	    let items = []
	    if(this.state.paginator.hasPage(this.props.currentProject.id, this.state.key, this.state.page)){
	    	const p = this.state.paginator.getData(this.props.currentProject.id, this.state.key, this.state.page);
	    	items = p.items;
	    } else {
	    	//fetch and set state as loading
	    	this.fetchPage(this.state.page);
	    }
	    //console.log("Items", JSON.stringify(items));
	    //render our items
	    if (items !== undefined){
			list = items.map(function(id){
				let item = this.props.networksById[id];
				if (item){
					return(
					<tr key={item.id}>
				      <th scope="row"><input type="checkbox" /></th>
				      <td><i className="fas fa-eye selectable" onClick={(e)=>this.viewNetwork(item)}></i></td>
				      <td>{item.name ? item.name : "Network "+item.id}</td>
				      <td>{item.id}</td>
				      <td>{item.accuracy ? "yes" : "no"}</td>
				      <td>{item.accuracy ? item.accuracy : "N/A"}</td>
				      <td>{item.loss ? item.loss : "N/A"}</td>
				    </tr>
				    )
				}
			}.bind(this));
		}

		let leftArrowDisabled = "page-item";
		let rightArrowDisabled = "page-item";
		if (this.state.page === 1){
			leftArrowDisabled = "page-item disabled";
		}
		if (this.state.page === this.state.totalPages){
			rightArrowDisabled = "page-item disabled";
		}


		return (
		<div class="jumbotron animated fadeIn">
		<div className="row">
			<div className="col-sm-4">
				<h3 class="">{title}</h3>
			</div>
			<div className="col-sm-4">
				<form class="form-inline my-2 my-lg-0">
          
		            <input value={search} style={{width:"90%"}} onChange={(e) => this.updateSearch(e)} className="form-control mr-sm-2 bg-secondary" type="text" placeholder="Search" />{cancelBtn}
		         
		        </form>

			</div>
			<div className="col-sm-4">
				<div>
					<ul class="pagination">
			    <li className={leftArrowDisabled} >
			      <a class="page-link" href="#" onClick={(e) => this.prevPage(e)}>&laquo;</a>
			    </li>
			    <li class="page-item disabled">
			    	<a class="page-link" href="#">{this.state.page} of {totalPages}</a>
			    </li>
			    <li className={rightArrowDisabled}>
			      <a class="page-link" href="#" onClick={(e) => this.nextPage(e)}>&raquo;</a>
			    </li>
			  </ul>
			</div>
			</div>

		</div>
			<table class="table table-hover">
			  <thead>
			    <tr>
			      <th><input type="checkbox" /></th>
			      <th><i className="fas fa-eye"></i></th>
			      <th scope="col" onClick={(e) => this.columnCallback("name")}>Name</th>
			      <th scope="col" onClick={(e) => this.columnCallback("id")}>ID</th>
			      <th scope="col" onClick={(e) => this.columnCallback("trained")}>Trained</th>
			      <th scope="col" onClick={(e) => this.columnCallback("accuracy")}>Accuracy</th>
			      <th scope="col" onClick={(e) => this.columnCallback("loss")}>Loss</th>
			    </tr>
			  </thead>
			  <tbody>
			    {list}
			   </tbody>
			</table>
			
  
		</div>
		)
	}
}

const mapStateToProps = state => {
  //console.log("mapStateToProps", Object.keys(state.accountReducer.networksByProject));
  return {
    //accountReducer
    
    init_loaded:state.accountReducer.init_loaded,
    projectsLoaded:state.accountReducer.projectsLoaded,
    api: state.accountReducer.api,
    currentAccount: state.accountReducer.currentAccount,
    projectsByAccount: state.accountReducer.projectsByAccount,
    currentProject: state.accountReducer.currentProject,
    networksByProject: state.accountReducer.networksByProject,
    networksForProjectLoaded: state.accountReducer.networksForProjectLoaded,
    networksById: state.accountReducer.networksById,

  }
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeProject: (x) => dispatch({type: "SWITCH_PROJECT", project:x}),
    onRequestNetworks: () => dispatch({type:"REQUEST_NETWORKS"}),
    onNetworksSuccess: (networks) => dispatch({type:"REQUEST_NETWORKS_SUCCESS", networks:networks}),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PagTable);
