import React, { Component } from "react";
import { connect } from 'react-redux'



class Navbar extends Component {




	render (){


		

		return (
			<nav class="navbar navbar-expand-lg  navbar-dark bg-primary border-bottom-brn">
			  <a class="navbar-brand" href="/">Prolific Orchard <i class="fas fa-code-branch"></i></a>
			  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
			    <span class="navbar-toggler-icon"></span>
			  </button>

			  <div class="collapse navbar-collapse" id="navbarColor01">
			    <ul class="navbar-nav mr-auto">
			      <li class="nav-item active">
			        <a class="nav-link" href="/">Projects <span class="sr-only">(current)</span></a>
			      </li>
			       <li class="nav-item active">
			        <a class="nav-link" href="/datasets">Datasets <span class="sr-only">(current)</span></a>
			      </li>
			      <li class="nav-item active">
			        <a class="nav-link" href="/train">Train <span class="sr-only">(current)</span></a>
			      </li>
			      <li class="nav-item active">
			        <a class="nav-link" href="/optimize">Optimize <span class="sr-only">(current)</span></a>
			      </li>
			      <li class="nav-item active">
			        <a class="nav-link" href="/deploy">Deploy <span class="sr-only">(current)</span></a>
			      </li>
			      <li class="nav-item active">
			        <a class="nav-link" href="/admin">Admin <span class="sr-only">(current)</span></a>
			      </li>
			    </ul>
			    
			    <form class="form-inline my-2 my-lg-0">

			    	
			      <button class="btn btn-primary my-2 my-sm-0" type="submit"><a class="btn-primary" href="/account">Account</a></button>
			    </form>
			  </div>

			</nav>

		)
	}
}


const mapStateToProps = state => {
  //console.log("mapStateToProps", state);
  return {
    //accountReducer
    loading: state.accountReducer.loading,
    error: state.accountReducer.error,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onInit: () => dispatch({type:"INIT"}),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
