/*

Api request functions 
wrapped in a class for ease of use

all requests will return an object, 

{
	status:<int>,
	data:<object,null>,
	error:<string, null>,

}

*/

const axios = require('axios');
const moment = require('moment');


class Api {

	constructor(url_base = "https://api.prolificorchard.com", headers = {}, timeout = 1000){
			
		if (process.env.NODE_ENV === 'development') {
		    url_base = "http://0.0.0.0:5000";
		} 

		//console.log(url_base, process.env);

		headers['Content-Type'] = "application/json";

		this.r = axios.create({
		  baseURL: url_base,
		  timeout: timeout,
		  headers: headers,
		});

		//default, set token headers if we have a valid token
		if (this.hasToken()){
			this.setTokenHeaders();
		}

	}

	hasToken(){
		//check whether this user has a login token
		const token = localStorage.getItem('TOKEN');
		const expires = localStorage.getItem("TOKEN-EXPIRES");
		const today_unix = moment().unix();

		if (token && expires > today_unix) {
			return true;
		}
		return false;
	}

	setTokenHeaders(){
		//sets our custom token headers for our requests
		const token = localStorage.getItem('TOKEN');
		if (token) {
			this.r.defaults.headers.common['api-token'] = token;
		}
		return;
	}

	setAuthToken(token, expires){
		//set our auth token and set our headers
		localStorage.setItem('TOKEN', token);
	  	localStorage.setItem('TOKEN-EXPIRES', expires);
	}

	logout(){
		localStorage.setItem('TOKEN', null);
	  	localStorage.setItem('TOKEN-EXPIRES', 0);
	}

	//persist an item to local storage
	persistItem(key, value){
		localStorage.setItem(key, value);
	}

	//get an item from local storage
	getItem(key){
		return localStorage.getItem(key);
	}

	getAuthToken(email, password, callback){
		//login using email and password, 		
		this.r.request({
			method:"POST",
			url:"/token",
			responseType:"json",
			data:{email:email, password:password}
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));	  
	}

	getAccounts(callback){
		this.r.request({
			method:"GET",
			url:"/accounts",
			responseType:"json"
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));
	}

	getProjects(account_id, callback){
		this.r.request({
			method:"GET",
			url:`/accounts/${account_id}/projects`,
			responseType:"json"
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));
	}

	postProject(account_id, project_name, projectConfig, callback){
		this.r.request({
			method:"POST",
			url:`/accounts/${account_id}/projects`,
			responseType:"json",
			data:{name:project_name, config:projectConfig}
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));
	}

	getLayerSchema(callback){
		this.r.request({
			method:"GET",
			url:`/schema`,
			responseType:"json"
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));
	}

	getNetworks(account_id, project_id, callback){
		this.r.request({
			method:"GET",
			url:`/accounts/${account_id}/projects/${project_id}/networks`,
			responseType:"json"
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));
	}

	postNetworks(account_id, project_id, networks, callback) {
		//note this endpoint accepts a list of networks. 
		this.r.request({
			method:"POST",
			url:`/accounts/${account_id}/projects/${project_id}/networks`,
			responseType:"json",
			data:networks,
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));
	}

	putNetwork(account_id, project_id, network_id, network, callback) {
		//note this endpoint accepts a list of networks. 
		this.r.request({
			method:"PUT",
			url:`/accounts/${account_id}/projects/${project_id}/networks/${network_id}`,
			responseType:"json",
			data:network,
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));
	}

	getNetworksPaginated(account_id, project_id, page=1, key="id", order="desc", filters={}, callback){
		let url = `/accounts/${account_id}/projects/${project_id}/networks?page=${page}&key=${key}&order=${order}`
		
		for (const k in filters){
			url = url + `&${k}=${filters[k]}`
		}
		return this.r.request({
			method:"GET",
			url:url,
			responseType:"json"
		}).then((response) => callback(response))
		.catch((error) => callback(error.response));
	}

	// Fetch methods, used in sagas
	// don't include callbacks
	// don't include catch
	fetchAccounts(){
		return this.r.request({
			method:"GET",
			url:"/accounts",
			responseType:"json"
		}).then((response) => response)
	}

	fetchProjects(account_id){
		//console.log("fetchProjects", account_id)
		return this.r.request({
			method:"GET",
			url:`/accounts/${account_id}/projects`,
			responseType:"json"
		}).then((response) => response)
	}

	fetchNetworks(account_id, project_id, page=1, key="id", order="desc", filters={}){
		let url = `/accounts/${account_id}/projects/${project_id}/networks?page=${page}&key=${key}&order=${order}`
		
		for (const k in filters){
			url = url + `&${k}=${filters[k]}`
		}
		return this.r.request({
			method:"GET",
			url:url,
			responseType:"json"
		}).then((response) => response)
	}

}

export default Api;