import React, { Component } from "react";


class StringInput extends Component {

	constructor(props){
    	super(props);

    	this.state = {
    		error:null,
    		value:this.props.defaultValue,
    	}

    }

    updateValue(e, itemKey, objectId, callback){

        callback(e.target.value, itemKey, objectId);
        this.setState({value:e.target.value});
    }



    render(){
    	let {
            objectId,
            itemKey,
    		title,
    		defaultValue,
    		min,
    		max,
    		step,
    		description,
            updateCallback,
    	} = this.props;

        let {value} = this.state;



    	return (

    		<div class="form-group">
	          <label >{title} </label>
	          <input onChange={(e) => this.updateValue(e, itemKey, objectId, updateCallback)}  defaultValue={value} type="text" class="form-control" />
	          <small id="" class="form-text text-muted">{description}</small>
	        </div>

    	)
    }

}

export default StringInput;