import React, { Component } from "react";





class LeftCol extends Component {


	 constructor(props){
      super(props)
      
    }

    componentDidMount () {
      //console.log("lc", this.myInput.current.offsetWidth)
    }


  accountsContext(){
    return (
      <div class="col-sm-3 bg-primary" id="leftCol">
      </div>
    )
  }

  projectsContext(account_name, projects){
    //default if no project is selected
    //render a menu to display all projects in this account

    let list = projects.map(item => 
            <li key={item.id} class="list-group-item d-flex justify-content-between align-items-center">
              {item.title}
              <span class="badge badge-primary badge-pill">{item.networks}</span>
            </li>
          )
    if (projects.length == 0){
      list = (<li class="list-group-item d-flex justify-content-between align-items-center">
        <p>Looks like you have no projects, click <a href="#" class="text-primary">Add Project</a> to create a new one.</p>
      </li> )
    }
    return (
      <div class="col-sm-3 bg-primary zeropad" id="leftCol">
        <ol class="breadcrumb ">
            <li class="breadcrumb-item "><a href="#" class="text-white">{account_name}</a></li>
        </ol>
        <div class="card text-white bg-primary mb-3" >
          <div class="card-header">ALL PROJECTS <button class="btn-primary float-right">Add project <i class="far fa-plus-square"></i></button></div>
        </div>

         <ul class="list-group">

          {list}

        </ul>
      </div>
    )
  }

	render (){
    const {
      currentAccount,
      currentProject,
      currentNetwork,
      projects,
    } = this.props

    const projectName = ((currentProject) ? currentProject.name : 'Projects');
    const networkName = ((currentNetwork) ? `Network ${currentNetwork.id}` : '');

   
    return this.projectsContext(currentAccount.name, projects)
	}
}

export default LeftCol;