
import React, { Component } from "react";

class Card extends Component {
  render(){
    const {
      onClick,
      className = 'card bg-secondary mb-3', //default value
      header,
      title,
      children,
    } = this.props
    return (
      <div class="card bg-secondary mb-3">
		<div class="card-header">{header}</div>
		<div class="card-body">
		<h4 class="card-title">{title}</h4>
		 {children}
		</div>
	</div>
    )
  }
}

export {
	Card,
};
