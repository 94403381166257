import { put, takeEvery, takeLatest, all, call, fork, select } from 'redux-saga/effects'
import axios from 'axios';
import Api from '../api';


//references to get items from our current state
const getApi = (state) => state.accountReducer.api;
const getCurrentAccount = (state) => state.accountReducer.currentAccount
const getCurrentProject = (state) => state.accountReducer.currentProject
const getProjectsByAccount = (state) => state.accountReducer.projectsByAccount
const getNetworkPaginator = (state) => state.paginationReducer.networkPaginator

//WORKERS

//base init function
//initialize our api, accounts and projects
function* baseInit(){
	yield put({type:"API_INIT"})
	yield put({type:"REQUEST_LAYER_SCHEMA"})
	yield put({type:"REQUEST_ACCOUNTS"})
	yield put({type:"REQUEST_PROJECTS"})
	yield put({type:"INIT_SUCCESS"})
}

//initialize our api singleton and add it to our state
function* apiInit(){
	console.log("apiInit");
	let api = new Api();
	yield put({ type: 'API_INIT_SUCCESS', api });
}

//base action to initialize our accounts
function* apiRequestAccounts(){
	let api = yield select(getApi); // <-- get the api
	
	try {
		let resp = yield call(api.fetchAccounts.bind(api)) 
		//console.log("api_request", resp)
		if (resp.status === 200) {
			let accounts = resp.data; 
			let currentAccount = resp.data[0];
			yield put({type:"REQUEST_ACCOUNTS_SUCCESS", accounts:accounts})
			let currentAccountState = yield select(getCurrentAccount);
			if (!api.getItem("id_account") || !currentAccountState){
				//default behaviour is switch to an account if none is currently selected
				yield put({type:"SWITCH_ACCOUNT", currentAccount:currentAccount})
			}
			
		}
	} catch(error) {
		console.log(error)
		yield put({type:"REQUEST_ACCOUNTS_FAILURE", error:error})
	}
}

//base action to initialize our projects
function* apiRequestProjects(){
	let api = yield select(getApi); // <-- get the api
	
	//check for current account
	let currentAccount = yield select(getCurrentAccount);
	let id_account = 0;
	if (!currentAccount && !api.getItem("id_account")){
		yield put({type:"REQUEST_PROJECTS_FAILURE", error:"No current id_account set"})
		return
	} else if (currentAccount){
		id_account = currentAccount.account_id
	} else {
		id_account = parseInt(api.getItem("id_account"))
	}

	//check for current project
	let currentProject = yield select(getCurrentProject);
	let id_project = 0;
	if (!currentProject && !api.getItem("id_project")){
		//...
	} else if (currentProject){
		id_project = currentProject.id
	} else {
		id_project = parseInt(api.getItem("id_project"))
	}

	try {
		let resp = yield call(api.fetchProjects.bind(api), id_account) 
		//console.log("api_request", resp)
		if (resp.status === 200) {
			let projects = resp.data; 

			
			//update our projects listing
			yield put({
				type:"REQUEST_PROJECTS_SUCCESS", 
				id_account:id_account, 
				projects:projects,
			})

			//switch to our new project
			if (!currentProject && id_project && id_project > 0){
				//we have a local storage of a project, load it
				//get our project by id and switch context
				for (var i in projects) {
					let p = projects[i]
					if (p.id == id_project){
						yield put({
						type:"SWITCH_PROJECT", 
						project:p,
					})
					break;
					}
				}
			}


			
		}
	} catch(error) {
		yield put({type:"REQUEST_PROJECTS_FAILURE", error:error})
	}
}


//get networks for the current project (uses paginator)
function* apiRequestNetworks(x){
	let api = yield select(getApi); // <-- get the api

	let paginator = yield select(getNetworkPaginator); //get current paginator
	if (x){
		console.log("api", x)
	}
	const page = paginator.page; //default is current page
	const key = paginator.key;
	const order = paginator.order;
	

	//check for current account
	let currentAccount = yield select(getCurrentAccount);
	let id_account = 0;
	if (!currentAccount && !api.getItem("id_account")){
		yield put({type:"REQUEST_NETWORKS_FAILURE", error:"No current id_account set"})
		return
	} else if (currentAccount){
		id_account = currentAccount.account_id
	} else {
		id_account = parseInt(api.getItem("id_account"))
	}

	//check for current project
	let currentProject = yield select(getCurrentProject);
	let id_project = 0;
	if (!currentProject && !api.getItem("id_project")){
		yield put({type:"REQUEST_NETWORKS_FAILURE", error:"No current id_project set"})
		return
	} else if (currentProject){
		id_project = currentProject.id
	} else {
		id_project = parseInt(api.getItem("id_project"))
	}
	
	try {
		let resp = yield call(api.fetchNetworks.bind(api), id_account, id_project, page) 
		console.log("api_request", resp)
		if (resp.status === 200) {
			let networks = resp.data.networks; 
			console.log("fetchNetworks", resp.data);
			
			yield put({
				type:"REQUEST_NETWORKS_SUCCESS", 
				id_account:id_account, 
				id_project:id_project,
				networks:networks,
				has_next:resp.data.has_next,
				page:resp.data.page,
				sort_by:resp.data.sort_by,
				total_pages:resp.data.total_pages,
			})
			//for some reason, REQUEST_NETWORKS_SUCCESS doesn't trigger a refresh
			yield put({
				type:"NETWORKS_LOADED", 
			})
			
		}
	} catch(error) {
		yield put({type:"REQUEST_NETWORKS_FAILURE", error:error})
	}
}


//get our layer schema (available layer types)
function* apiRequestLayerSchema(){
	let api = yield select(getApi); // <-- get the api
		
	try {
		let resp = yield call(api.fetchLayerSchema.bind(api)) 
		console.log("api_request", resp)
		if (resp.status === 200) {
			let networks = resp.data; 
			console.log("fetchNetworks", resp.data);
			
			yield put({
				type:"REQUEST_LAYER_SCHEMA_SUCCESS", 
				layerSchema:resp.data,
			})
			
		}
	} catch(error) {
		yield put({type:"REQUEST_LAYER_SCHEMA_FAILURE", error:error})
	}
}



//WATCHERS
function* watchInit(){
	yield takeLatest("INIT", baseInit)
}

function* watchApiInit(){
	yield takeLatest('API_INIT', apiInit);
}

function* watchApiRequestAccounts(){
	yield takeLatest("REQUEST_ACCOUNTS", apiRequestAccounts);
}

function* watchApiRequestProjects(a){
	console.log("WATCH API", a);
	yield takeLatest("REQUEST_PROJECTS", apiRequestProjects);
}

function* watchApiRequestNetworks(){
	yield takeLatest("REQUEST_NETWORKS", apiRequestNetworks);
}

function* watchRequestLayerSchema(){
	yield takeLatest("REQUEST_LAYER_SCHEMA", apiRequestLayerSchema);
}



export default function* rootSaga() {
  yield all([
    fork(watchInit),
    fork(watchApiInit),
    fork(watchApiRequestAccounts),
    fork(watchApiRequestProjects),
    fork(watchApiRequestNetworks),
    fork(watchRequestLayerSchema),
  ])
}