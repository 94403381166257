/*

pagination class

//network example
//id_project --> subKey (eg: accuracy) --> {pages:{1:{items:[1,2,3,4],fetching:false}}, totalPages:3}

{
	1:{
		"id":{
			pages:{
				1:{
					items:[1,2,3,4],
					fetching:false,
				},
				2:{
					items:[5,6,7,8],
					fetching:false,
				},
			},
			totalItems:200,
			totalPages:2
		},
	},
}

*/


// wraps a paged response from api 
class Page {
	constructor(
			page=1,
			items=[],
			perPage=20,
			totalPages=0,
			hasNextPage=false){
		this.page = page;
		this.items = items;
		this.perPage = perPage;
		this.totalPages = totalPages;
		this.hasNextPage = hasNextPage;
	}
}

//paginator wraps and maintains complex pagination states
class Paginator {
	constructor(){
		this.data = {}
		this.page = 1; //current page
		this.key = "id"; //current way we are sorting paginating 
		this.order = "asc"; //(asc)ending or (desc)ending order
		this.filters = {};
		this.loading = false;
	}


	setLoading(loading){
		this.loading = loading;
	}

	getLoading(){
		return this.loading;
	}

	setKey(key){
		this.key = key;
	}

	getKey(){
		return this.key
	}

	setOrder(order){
		this.order = order
	}

	getOrder(){
		return this.order
	}

	setPage(page){
		this.page = page;
	}

	getPage(){
		return this.page
	}

	setFilter(key, value){
		this.filters[key] = value;
	}

	delFilter(key){
		delete this.filters[key]
	}

	addData(p, id=0, key="by_id", page=1){
		//add a new page to this collection
		//check if this id exists
		if (!this.data[id]){
			this.data[id] = {};
		}

		//check if this key exists
		if (!this.data[id][key]){
			this.data[id][key] = {pages:{}, meta:{totalPages:p.totalPages, perPage:p.perPage}};
		}

		//add this page object by page #
		this.data[id][key]["pages"][page] = p;

	}

	getData(id=0, key="by_id", page=-1){
		//default is current page
		if (page === -1){
			page = this.page;
		}

		if (!this.hasPage(id, key, page)){
			return null
		}

		//return this page from this collection by id, key, & subkey
		return this.data[id][key]["pages"][page];
	}

	hasPage(id, key, page){
		//check if this id exists
		if (!this.data[id]){
			return false
		}

		//check if this key exists
		if (!this.data[id][key]){
			return false
		}
		//check if this page exists
		if (!this.data[id][key]["pages"][page]){
			return false
		}

		return true
	}

	nextPage(id, key){
		//if next page exists, increment current page and return
		const page = this.page + 1;
		if (!key){
			key = this.key;
		}
		if (this.hasPage(id, key, page)){
			return this.getData(id, key)
		}
		return null
	}

	prevPage(id, key){

		//if prev page exists, decrement current page and return
		const page = this.page - 1;
		if (page === 0){
			//just return page 1
			return this.getData(id, key, 1);
		}
		if (!key){
			key = this.key;
		}
		if (this.hasPage(id, key, page)){
			return this.getData(id, key)
		}
		return null
	}

}


export {
	Paginator,
	Page,
};