
import React, { Component } from "react";
import {Button} from '../../components/button'
import Api from '../../api';
import CreateProject from './CreateProject'
import styled from 'styled-components'
import Footer from '../footer';
import LeftCol from "./LeftCol";
import RightCol from "./RightCol";
import Content from "./Content";
import NetworkDesigner from "./NetworkDesigner";
import TrayItem from './TrayItem';
import moment from 'moment'


import { connect } from 'react-redux'


class Home extends Component {

  /* 

  currentView (state machine) states:
  loading (show a loading div)
  accounts (see all accounts, not implemented yet, defaults to primary account)
  
  projects (see all projects for this account)
  createProject (see create project menu)
  viewProject (see a project and all of it's networks)
  network (see / edit a current neural network)

  datasets (see all datasets for this account)
  createDataset (add a dataset)
  

  */

  constructor(props){
    super(props);
    
    
    let {currentView} = props;
    if (!currentView){
      currentView = "loading"
    }
  

    //check if user is logged in
    this.api = new Api();
    if (!this.api.hasToken()){
      window.location = '/login'
    }

    this.state = {
      accounts:[],
      projectsByAccount:{},
      networksByProject:{},
      currentView:currentView,
      currentAccount:null,
      currentProject:null,
      currentNetwork:null,
      currentDataset:null,
      projectName:"",
      error:null,
      layerSchema:{},
      layerSearch:"",
      dateLastSaved:"",
    }

    this.viewCreateProject = this.viewCreateProject.bind(this);
    this.projectNameChange = this.projectNameChange.bind(this);
    this.postProject = this.postProject.bind(this);
    this.viewProjects = this.viewProjects.bind(this);
    this.viewProject = this.viewProject.bind(this);
    this.viewCreateNetwork = this.viewCreateNetwork.bind(this);
    this.updateLayerSearch = this.updateLayerSearch.bind(this);
    this.clearLayerSearch = this.clearLayerSearch.bind(this);
    this.saveNetwork = this.saveNetwork.bind(this);
    this.viewNetwork = this.viewNetwork.bind(this);

  }

  componentDidMount(){

    //console.log("props", this.props);
    //console.log("state", this.state);

    //initialize our api singleton
    this.props.onInitApi();

    
    const {loadProjects} = this.props;
    if (loadProjects){
      this.loadProjects();
    }
    this.loadLayerSchema();
    
  }

  shouldComponentUpdate(nextProps, nextState){
    //console.log("shouldComponentUpdate");
    //console.log(nextProps);
    // console.log(nextState);
    return true;
  }

  /* API FUNCS */

  loadProjects(){
    //load accounts, then projects, then set our currentView

    let accountPromise = new Promise (function(resolve, reject){
      setTimeout(function() {
         this.getAccounts()
        resolve()
      }.bind(this), 1000);
     
    }.bind(this));

    let projectPromise = new Promise(function(resolve, reject){
      this.getAccounts()
      resolve()
    }.bind(this));
    
    accountPromise.then(function(){
      this.setState({currentView:"projects"})
    }.bind(this));
    
  }

  loadLayerSchema(){
    this.api.getLayerSchema(function(resp){
      console.log("get layer_schema");
      if (!resp){
          console.log("nothing");
      } else if (resp.status === 200) {
        this.setState({layerSchema:resp.data});
      } else {
        console.log("error", resp.status, resp.data);
      }
    }.bind(this));
  }

  getAccounts(){
    
    this.api.getAccounts(function(resp){
      console.log("get accounts");
      if (!resp){
          console.log("nothing");
      } else if (resp.status === 200) {
        this.setState({accounts:resp.data, currentAccount:resp.data[0]});
        this.getProjects(resp.data[0].account_id);
        this.props.onChangeAccount(resp.data[0]);
      } else {
        console.log("error", resp.status, resp.data);
      }

    }.bind(this));
  }

  getProjects(account_id){
    this.api.getProjects(account_id, function(resp){
      console.log("get projects");
      if (!resp){
      } else if (resp.status === 200) {
        let projectsByAccount = this.state.projectsByAccount
        projectsByAccount[account_id] = resp.data
        this.setState({projectsByAccount:projectsByAccount})
      } else {
        console.log("error", resp.status, resp.data);
      }

    }.bind(this));
  }

  postProject(projectConfig){
    const { currentAccount} = this.state;
    const projectName = projectConfig["name"];
    this.api.postProject(currentAccount.account_id, projectName, projectConfig, function(resp){
      console.log("post project");
      if (!resp){
          console.log("nothing");
      } else if (resp.status === 201) {
        this.getProjects(currentAccount.account_id);
        this.setState({currentView:"projects"});
      } else {
        console.log("error", resp.status, resp.data);
      }
    }.bind(this));
  }

  getNetworks(account_id, project_id){
    this.api.getNetworks(account_id, project_id, function(resp){
      console.log("get networks", resp);
      if (!resp){

      } else if (resp.status === 200) {
        
        let {networksByProject} = this.state;
        networksByProject[project_id] = resp.data;
        this.setState({networksByProject:networksByProject});

      } else {
        console.log("error", resp.status, resp.data);
      }

    }.bind(this));
  }

  saveNetwork(networkConfig){
    const { currentAccount, currentProject, currentNetwork} = this.state;
    
    if(currentNetwork){
      console.log("update network");
      //updating a network
      let network = networkConfig;
      this.api.putNetwork(currentAccount.account_id, currentProject.id, currentNetwork.id, network, function(resp){
        console.log("update", resp);
        if (!resp){
            console.log("nothing");
        } else if (resp.status === 200) {
          this.getNetworks(currentAccount.account_id, currentProject.id);
          if (resp.data.date_updated !== null) {
            const dateString = moment(resp.data.date_updated).format("MMMM Do YYYY, h:mm:ss a");
            this.setState({dateLastSaved:dateString});
          }
          
        } else {
          console.log("error", resp.status, resp.data);
        }
      }.bind(this));
    } else {
      console.log("create network", [networkConfig]);
      //create a new network
      this.api.postNetworks(currentAccount.account_id, currentProject.id, [networkConfig], function(resp){
        console.log("create", resp);
        if (!resp){
            console.log("nothing");
        } else if (resp.status === 201) {
          this.getNetworks(currentAccount.account_id, currentProject.id);
          if (resp.data.date_created !== null) {
            var dateString = moment(resp.data.date_created).format("mm/Do/YYYY, h:mm:ss a");
            this.setState({dateLastSaved:dateString});
          }
          //this.setState({currentView:"viewProject"});
        } else {
          console.log("error", resp.status, resp.data);
        }
      }.bind(this));
    }

    
  }

  /* STATE FUNCS */
  projectNameChange(event){
    this.setState({projectName:event.target.value});
  }

  updateLayerSearch(event){
    this.setState({layerSearch:event.target.value});
  }

  clearLayerSearch(){
    this.setState({layerSearch:""});
  }

  /* CURRENT VIEW FUNCS */

  viewProjects(account_id){
    this.setState({currentView:"projects"});
  }

  viewCreateProject(){
    this.setState({currentView:"createProject"});
  }

  viewProject(project){
    const {currentAccount} = this.state;
    this.getNetworks(currentAccount.account_id, project.id);
    this.setState({currentView:"viewProject", currentProject:project});
    this.props.onChangeProject(project);
  }

  viewCreateNetwork(){
    this.setState({currentView:"createNetwork", currentNetwork:null});
  }

  viewNetwork(network){
    this.setState({currentView:"viewNetwork", currentNetwork:network});
    this.props.onChangeNetwork(network);
  }

  viewDatasets(){
    this.setState({currentView:"datasets"});
  }

  /* RENDER FUNCS 
     todo: move these to components
  */

  renderProjects(){
    const {
      projectsByAccount,
      networksByProject,
      currentView,
      currentAccount,
      currentProject,
      currentNetwork,
      currentDataset,
    } = this.state;

    //todo: move to a reusable component
    let projects = [];
    let account_name = "";
    if (currentAccount){
      account_name = currentAccount.name;
      projects = projectsByAccount[currentAccount.account_id];
    }
    if (projects === undefined) {
      projects = [];
    }

    let list = projects.map(item => 
            <li onClick={(e) => this.viewProject(item)} key={item.id} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" style={{cursor:"pointer"}}>
              {item.name}
              <span class="badge badge-primary badge-pill">{item.num_networks}</span>
            </li>
          )
    if (projects.length == 0){
      list = (<li class="list-group-item d-flex justify-content-between align-items-center">
        <p>Looks like you have no projects, click <a href="#" onClick={this.viewCreateProject} class="text-primary">Add Project</a> to create a new one.</p>
      </li> )
    }

    return (
      <div>
        <ol class="breadcrumb ">
            
            <li class="breadcrumb-item "><a onClick={(e) => this.viewProjects(currentAccount)} href="#" class="text-white">Projects</a></li>
        </ol>
        <div class="card text-white bg-primary mb-3" >
          <div class="card-header">ALL PROJECTS 
            <button onClick={this.viewCreateProject} class="btn-primary float-right">Add project <i class="far fa-plus-square"></i>
            </button>
          </div>
        </div>

         <ul class="list-group">

          {list}

        </ul>
      </div>
      
      )
  }

  renderSingleProjectSidebar(){
    const {
      projectsByAccount,
      networksByProject,
      currentView,
      currentAccount,
      currentProject,
      currentNetwork,
      currentDataset,
    } = this.state;


    let networks = [];
    
    if (currentProject){
      console.log("renderSingleProjectSidebar, networksByProject", networksByProject);
      networks = networksByProject[currentProject.id];
    }
    if (networks === undefined) {
      networks = [];
    }

    let list = networks.map(item => 
            <li onClick={(e) => this.viewNetwork(item)} key={item.id} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" style={{cursor:"pointer"}}>
              Network {item.id}
              <span class="badge badge-primary badge-pill">{item.accuracy}</span>
            </li>
          )

    return (
      <div>
        <ol class="breadcrumb ">
            
            <li class="breadcrumb-item "><a onClick={(e) => this.viewProjects(currentAccount)} href="#" class="text-white">Projects</a></li>
             <li class="breadcrumb-item "><a onClick={(e) => this.viewProject(currentProject)} href="#" class="text-white">{currentProject.name}</a></li>
        </ol>
        <div class="card text-white bg-primary mb-3" >
          <div class="card-header">NETWORKS
            <button onClick={this.viewCreateNetwork} class="btn-primary float-right">New Network <i class="far fa-plus-square"></i>
            </button>
          </div>
        </div>

         <ul class="list-group">

          {list}

        </ul>
      </div>
      
      )
  }

  renderCreateNetworkSidebar(){
    const {
      projectsByAccount,
      networksByProject,
      currentView,
      currentAccount,
      currentProject,
      currentNetwork,
      currentDataset,
      layerSchema,
      layerSearch,
    } = this.state;

    let networks = [];
    
    if (currentProject){
      
      networks = networksByProject[currentProject.id];
    }
    if (networks === undefined) {
      networks = [];
    }

    let networkName = "New Network";
    if (currentNetwork) {
      networkName = "Network "+currentNetwork.id;
    }

    let list = networks.map(item => 
            <li onClick={(e) => this.viewNetwork(item)} key={item.id} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" style={{cursor:"pointer"}}>
              {item.name}
              <span class="badge badge-primary badge-pill">{item.accuracy}</span>
            </li>
          )

    let layers = Object.keys(layerSchema).map(function(key, index) {
        return {id:index, key:key}
    });

    //add input / output layers
    layers.unshift({id:-1, key:"Input"});
    layers.unshift({id:-2, key:"Output"});

    //optional filter search
    let cancelBtn = (<div></div>);
    if (layerSearch.length > 0) {
      layers = layers.filter(item => 
        item.key.toLowerCase().includes(layerSearch.toLowerCase())
      )
      cancelBtn = (<i onClick={this.clearLayerSearch} class="fas fa-times-circle"></i>)
    }

    //generate our list of components
    let layerList = layers.map(item => 
      <TrayItem key={item.id} name={item.key} model={{type:item.key}}>
        <span class="badge badge-dark badge-pill"><i class="fas fa-database"></i></span>
      </TrayItem>
    )


    return (
      <div>
        <ol class="breadcrumb ">
            
            <li class="breadcrumb-item "><a onClick={(e) => this.viewProjects(currentAccount)} href="#" class="text-white">Projects</a></li>
             <li class="breadcrumb-item "><a onClick={(e) => this.viewProject(currentProject)} href="#" class="text-white">{currentProject.name}</a></li>
             <li class="breadcrumb-item active"> {networkName}</li>
        </ol>
        <div class="card text-white bg-primary mb-3" >
          <div class="card-header">LAYERS 
            <button onClick={this.viewCreateNetwork} class="btn-primary float-right">New Network <i class="far fa-plus-square"></i>
            </button>
          </div>
        </div>
        <form class="form-inline my-2 my-lg-0">
          
            <input value={layerSearch} style={{width:"90%"}} onChange={(e) => this.updateLayerSearch(e)} className="form-control mr-sm-2 bg-dark" type="text" placeholder="Search" />{cancelBtn}
         
        </form>
        
         <ul class="list-group customScroll " style={{height:"700px", overflowY:"scroll"}}>
          {layerList}
        </ul>
      </div>
      
      )
  }



  renderViewProject(){
    const {currentProject} = this.state;

    return (
      <div>
       <h1>{currentProject.name}</h1>
      </div>
      
      )
  }

	render (){

    const {
      projectsByAccount,
      networksByProject,
      currentAccount,
      currentProject,
      currentNetwork,
      currentDataset,
      layerSchema,
      dateLastSaved,
    } = this.state;

    let {currentView} = this.state;

    const {api} = this.props;
    if (!api) {
      currentView = "LOADING"
    }

    let context_menu = (<div></div>)
    let content = (<Content />)

    if (currentView === "loading"){
      content = (
        <Content >
          <div class="row">
            <div class="col-sm-3">
            </div>
            <div class="col-sm-3" style={{paddingTop:"100px"}}>
              <h3 class="text-center">LOADING</h3>
              <div class="rotating text-center" style={{fontSize:"40px", width:"40px", margin:"0 auto"}}>
                <i class="fas fa-cog"></i></div>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
        </Content>)

    } else if (currentView === "projects"){
      context_menu = this.renderProjects()
    } else if (currentView === "createProject"){
      context_menu = this.renderProjects()
      content = (<Content ><CreateProject onSubmit={this.postProject} /></Content>)
    } else if (currentView === "viewProject"){
      context_menu = this.renderSingleProjectSidebar();
      content = (<div className={"col-sm-9 dotted h-min"} style={{padding:"0px"}}>{this.renderViewProject()}</div>)
    } else if (currentView === "createNetwork"){
      console.log("createNetwork",currentNetwork);
      context_menu = this.renderCreateNetworkSidebar();
      content = (<Content classString="col-sm-9 dotted" style={{paddingTop:"0px"}}>
        <NetworkDesigner 
          layerSchema={layerSchema} 
          saveCallback={this.saveNetwork}
          network={currentNetwork}
        /></Content>)
    } else if (currentView === "viewNetwork"){
      console.log("viewNetwork",currentNetwork);
       context_menu = this.renderCreateNetworkSidebar();
       content = (
        <Content 
          classString="col-sm-9 dotted"
          style={{paddingTop:"0px"}}>
        <NetworkDesigner 
          layerSchema={layerSchema} 
          saveCallback={this.saveNetwork}
          network={currentNetwork}
          dateLastSaved={dateLastSaved}
        />
        </Content>)

    } else {
      console.log("undefined state for currentView", currentView)
    }
   
		return (
      <div>
			<div class="container-fluid h-100 h-min" >
          <div class="row h-100">
            <div class="col-sm-3 bg-primary border-right-brn" id="leftCol">
              {context_menu}
            </div>
            
             {content}
            
          </div>
        </div>
        <Footer />     
      </div>  

		)
	}

}

const mapStateToProps = state => {
  //console.log("mapStateToProps", state);
  return {
    //accountReducer
    loading: state.accountReducer.loading,
    api: state.accountReducer.api,
    currentAccount: state.accountReducer.currentAccount,
    }
};

const mapDispatchToProps = dispatch => {
  return {
    onInit: () => dispatch({type:"INIT"}),
    onInitApi: () => dispatch({type: "API_INIT"}),
    onChangeAccount: (account) => dispatch({type: "SWITCH_ACCOUNT", account:account}),
    onChangeProject: (project) => dispatch({type: "SWITCH_PROJECT", project:project}),
    onChangeNetwork: (network) => dispatch({type: "SWITCH_NETWORK", network:network}),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);