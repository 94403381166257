import React, { Component } from "react";

class RightCol extends Component {

	render (){
		return (
			<div class="col-sm-2 h-100 transparent" >
				
			</div>

		)
	}
}


export default RightCol;