import React, { Component } from "react";
import Api from '../../api';

class Account extends Component {

	constructor(props){
	    super(props);

	    this.state = {
			result: null,
			error:null,
		}

		this.api = new Api(); 

	    this.logout = this.logout.bind(this);
	 }

	 logout(){
	 	this.api.logout();
	 	window.location.href = '/login';

	 }

	 render (){


		return (
			<div class="container-fluid h-100" style={{height:"100%"}}>


				<div class="row" style={{paddingTop:"20px"}}>
					<div class="col-sm-4">
					</div>
					<div class="col-sm-4">
  				
		  				<form>
						  <fieldset>
						    <legend>Account</legend>
						    
						    <button onClick={this.logout} type="button" class="btn btn-primary">Logout</button>
						  </fieldset>
						</form>
					</div>
					<div class="col-sm-4">
					</div>  
				</div>				 
  			
  			</div>

		)
	}



}

export default Account;