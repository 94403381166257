import React, { Component } from "react";


class Table extends Component {
	constructor(props){
		super(props);

		this.state = {
			search:"",
		}

		this.updateSearch = this.updateSearch.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.viewNetwork = this.viewNetwork.bind(this);
	}

	updateSearch(event){
		this.setState({search:event.target.value});
	}

	clearSearch(){
		this.setState({search:""});
	}

	viewNetwork(item){

		if (this.props.selectNetworkCallback){
			this.props.selectNetworkCallback(item);
		}
	}

	render(){


		let {items, title, currentPage, totalPages} = this.props;
		const {search} = this.state;

		let list = [];

		
		let cancelBtn = (<div></div>);
	    if (search.length > 0) {
	      items = items.filter(function(item){
	      	let name = item.name ? item.name : "Network "+item.id
	        return name.toLowerCase().includes(search.toLowerCase())
	      })
	      cancelBtn = (<i onClick={this.clearSearch} class="fas fa-times-circle"></i>)
	    }

	    //render our items
	    if (items !== undefined){
			list = items.map(item => 
				<tr key={item.id}>
			      <th scope="row"><input type="checkbox" /></th>
			      <td><i className="fas fa-eye selectable" onClick={(e)=>this.viewNetwork(item)}></i></td>
			      <td>{item.name ? item.name : "Network "+item.id}</td>
			      <td>{item.id}</td>
			      <td>{item.accuracy ? "yes" : "no"}</td>
			      <td>{item.accuracy ? item.accuracy : "N/A"}</td>
			      <td>{item.loss ? item.loss : "N/A"}</td>
			    </tr>
			)
		}


		return (
		<div class="jumbotron animated fadeIn">
		<div className="row">
			<div className="col-sm-4">
				<h3 class="">{title}</h3>
			</div>
			<div className="col-sm-4">
				<form class="form-inline my-2 my-lg-0">
          
		            <input value={search} style={{width:"90%"}} onChange={(e) => this.updateSearch(e)} className="form-control mr-sm-2 bg-secondary" type="text" placeholder="Search" />{cancelBtn}
		         
		        </form>

			</div>
			<div className="col-sm-4">
				<div>
					<ul class="pagination">
			    <li class="page-item" >
			      <a class="page-link" href="#" onClick={(e) => this.props.prevPageCallback(e)}>&laquo;</a>
			    </li>
			    <li class="page-item disabled">
			    	<a class="page-link" href="#">{currentPage} of {totalPages}</a>
			    </li>
			    <li class="page-item">
			      <a class="page-link" href="#" onClick={(e) => this.props.nextPageCallback(e)}>&raquo;</a>
			    </li>
			  </ul>
			</div>
			</div>

		</div>
		
		
			<table class="table table-hover">
			  <thead>
			    <tr>
			      <th><input type="checkbox" /></th>
			      <th><i className="fas fa-eye"></i></th>
			      <th scope="col" onClick={(e) => this.props.columnCallback("name")}>Name</th>
			      <th scope="col" onClick={(e) => this.props.columnCallback("id")}>ID</th>
			      <th scope="col" onClick={(e) => this.props.columnCallback("trained")}>Trained</th>
			      <th scope="col" onClick={(e) => this.props.columnCallback("accuracy")}>Accuracy</th>
			      <th scope="col" onClick={(e) => this.props.columnCallback("loss")}>Loss</th>
			    </tr>
			  </thead>
			  <tbody>
			    {list}
			   </tbody>
			</table>
			
  
		</div>
		)
	}
}

export default Table;