import React, { Component } from "react";

class TrayItem extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		const {name, children} = this.props;
		return (
			<li
				style={{ borderColor: this.props.color }}
				draggable={true}
				onDragStart={event => {
					event.dataTransfer.setData("storm-diagram-node", JSON.stringify(this.props.model));
				}}
				className="list-group-item d-flex justify-content-between align-items-center active">
				{name}

				{children}
			</li>
		);
	}
}

export default TrayItem;