import React, { Component } from "react";
import moment from 'moment';
import Footer from '../footer';
import {Card} from '../../components/card';
import ReactJson from 'react-json-view'


class Admin extends Component {

	constructor(props){
    	super(props);

	}



	render (){


		return (
	      <div>
			<div className="container-fluid h-100 h-min" >
	          <div className="row h-100 h-min">
	            <div className="col-sm-3 bg-primary border-right-brn h-100 h-min" id="leftCol">
	             	<p>Context</p> 
	            </div>
	            <div className="col-sm-9 bd-primary">
	            	<br/>
	            	<Card 
	            		title={"hello"}
	            		header={"info"}
	            	>
	            		<p>This is the children</p>
	            	</Card>

	            </div>
	          </div>
	        </div>
	        <Footer />     
	      </div>  

			)
	}



}








// const mapStateToProps = state => {
//   //console.log("mapStateToProps", state);
//   return {
//     //accountReducer
//     loading: state.accountReducer.loading,
//     api: state.accountReducer.api,
//     currentAccount: state.accountReducer.currentAccount,

//     //test reducer
//     fetching: state.testReducer.fetching,
//     dog: state.testReducer.dog,
//     error: state.testReducer.dog
//   }
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     onInit: () => dispatch({type:"INIT"}),
//     onInitApi: () => dispatch({type: "API_INIT"}),
//     onChangeAccount: (account) => dispatch({type: "SWITCH_ACCOUNT", account:account}),
//     onChangeProject: (project) => dispatch({type: "SWITCH_PROJECT", project:project}),
//     onChangeNetwork: (network) => dispatch({type: "SWITCH_NETWORK", network:network}),
//   	onLoadLayerSchema: () => dispatch({type:"REQUEST_LAYER_SCHEMA"}),
//   }
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Home);

export default Admin;
