
import React, { Component } from "react";
const moment = require('moment');

class Footer extends Component {
  render(){
    const year = moment().year();
    return (
      <footer class="page-footer font-small bg-primary pt-4 border-top-brn">
  <div class="container-fluid text-center text-md-left">

    
    <div class="row">

    
      <div class="col-md-6 mt-md-0 mt-3">

       
        <h5 class="text-uppercase">Footer Content</h5>
        <p></p>

      </div>
      

      <hr class="clearfix w-100 d-md-none pb-3" />

      
      <div class="col-md-3 mb-md-0 mb-3">

      
        <h5 class="text-uppercase">Links</h5>

        <ul class="list-unstyled">
          <li>
            <a href="#!">Link 1</a>
          </li>
          <li>
            <a href="#!">Link 2</a>
          </li>
          <li>
            <a href="#!">Link 3</a>
          </li>
          <li>
            <a href="#!">Link 4</a>
          </li>
        </ul>

      </div>
      
      <div class="col-md-3 mb-md-0 mb-3">
        <h5 class="text-uppercase">Links</h5>

        <ul class="list-unstyled">
          <li>
            <a href="#!">Link 1</a>
          </li>
          <li>
            <a href="#!">Link 2</a>
          </li>
          <li>
            <a href="#!">Link 3</a>
          </li>
          <li>
            <a href="#!">Link 4</a>
          </li>
        </ul>

      </div>
     

    </div>
    

  </div>
  

  
  <div class="footer-copyright text-center py-3">© {year} Copyright Prolific Orchard
    
  </div>
  

</footer>


      )
  }


}


export default Footer;