import React from 'react';
import ReactDOM from 'react-dom';


import './bootstrap.css';
import './index.css';
import './css/fa/css/all.css';
import './css/animate.css'
import AppRouter from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './state/sagas'
import rootReducer from './state/reducers'


const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(rootSaga)

var element = document.getElementById("root");
element.classList.add("h-100");
ReactDOM.render(
	<Provider store={store}>
    	<AppRouter />
  	</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export {
	store,
}