import React, { Component } from "react";
import * as SRD from "storm-react-diagrams";

import NumberInput from './NumberInput';
import StringInput from './StringInput';
import OptionsInput from './OptionsInput';



require("storm-react-diagrams/dist/style.min.css")



class NetworkDesigner extends Component {


	constructor(props){
		super(props);

		this.state = {
			id2node:{}, //uuid to node component
			id2nodeModel:{}, //uuid to node model
			nodes:[],
			engine:null,
			model:null,
			selectedNode:null,
			offsetX:0,
			offsetY:0,
			layerSchema:this.props.layerSchema,
		}

		this.handleDrop = this.handleDrop.bind(this);
		this.updateNodePosition = this.updateNodePosition.bind(this);
		this.linksUpdated = this.linksUpdated.bind(this);
		this.nodesUpdated = this.nodesUpdated.bind(this);
		this.didSelect = this.didSelect.bind(this);
		this.gridUpdated = this.gridUpdated.bind(this);
		this.resetGrid = this.resetGrid.bind(this);
		this.saveNetwork = this.saveNetwork.bind(this);
		this.updateCallback = this.updateCallback.bind(this);
	}

	componentDidMount(){
		let {engine,model} = this.defineDiagram();
		this.setState({engine:engine, model:model})
	}

	componentDidUpdate(prevProps){
		//go from existing network --> new network
		//component doesn't mount, so force a re-definition of engine
		if (prevProps.network && this.props.network === null){
			let {engine,model} = this.defineDiagram();
			this.setState({engine:engine, model:model})
		}
	}


	/* listeners */

	updateNodePosition(d){
		console.log(d);
	}

	linksUpdated(l, isCreated) {
		//let {model} = this.state;
		console.log("link updates", l, isCreated);

	}

	nodesUpdated(n, isCreated) {
		console.log("node updates", n, isCreated);
	}

	didSelect(d){
		//console.log("didSelect", d);
		this.setState({selectedNode:d});
	}

	didSelectLink(d){
		console.log("didSelectLink", d);
	}

	gridUpdated(d){
		console.log("gridUpdated", d);
	}

	resetGrid(){
		console.log("resetGrid")
		let {model} = this.state;
		model.setOffset(0,0);
		this.setState({offsetX:0,offsetY:0});
	}

	handleDrop(e, data){
		let {nodes, engine, model, id2node, id2nodeModel, layerSchema} = this.state;
		let points = engine.getRelativeMousePoint(e);
		let defaultColor = "rgba(0,0,0,0.9)";
		let defaultTitle =  data.type.charAt(0).toUpperCase() + data.type.slice(1)
		let n = new SRD.DefaultNodeModel(defaultTitle, defaultColor);
		
		if (data.type === "Input") {
			n.addOutPort("Out");
		} else if (data.type === "Output") {
			n.addInPort("In");
		} else {
			n.addOutPort("Out");
			n.addInPort("In");
		}

		n.setPosition(points.x, points.y);
		n.addListener({
			positionChanged: this.updateNodePosition
		});
		let x = model.addAll(n);

		x.forEach(item => {
			item.addListener({
				selectionChanged: this.didSelect
			});
			id2node[item.id] = n;
			//add our default args/kwargs
			let defaultKwargs = [];
			let defaultArgs = [];
			if (layerSchema[data.type]){
				if (layerSchema[data.type]["args"]){
					defaultArgs = layerSchema[data.type]["args"];
				}
				if (layerSchema[data.type]["kwargs"]){
					defaultKwargs = layerSchema[data.type]["kwargs"];
				}
			}
			id2nodeModel[item.id] = {
				title:defaultTitle, 
				color:defaultColor,
				type:data.type,
				args:defaultArgs,
				kwargs:defaultKwargs,
			};

		});

		this.setState({
			nodes:nodes, 
			id2node:id2node,
			id2nodeModel:id2nodeModel,
		});
	}

	defineDiagram(){

		const {nodes, id2nodeModel} = this.state;
		let {network} = this.props;
		console.log("define diagram", nodes, network);
		// 1) setup the diagram engine
		var engine = new SRD.DiagramEngine();
		engine.installDefaultFactories();

		// 2) setup the diagram model
		var model = new SRD.DiagramModel();

		
		let modelNodes = [];
		let nodeMap = {};
		let links = [];

		//add existing nodes if they exist:
		if(network && network["config"] && network["config"]["layers"]){
			
			for (let i = 0; i < network["config"]["layers"].length; i++){
				let data = network["config"]["layers"][i];

				let defaultTitle = data.type.charAt(0).toUpperCase() + data.type.slice(1) + `    `;
				let defaultColor = "rgba(0,0,0,0.9)";
				var n = new SRD.DefaultNodeModel(defaultTitle, defaultColor);
				
				let inPort = null;
				let outPort = null;
				if (data.type === "Input") {
					outPort = n.addOutPort("Out");
				} else if (data.type === "Output") {
					inPort = n.addInPort("In");
				} else {
					outPort =  n.addOutPort("Out");
					inPort = n.addInPort("In");
				}
				n.setPosition(data.x, data.y);
				n["id"] = data.id
				nodeMap[n["id"]] = {node:n, inPort:inPort, outPort:outPort};
				modelNodes.push(n);
				id2nodeModel[n["id"]] = data;
			}
		}

		//add existing links if they exist
		if(network && network["config"] && network["config"]["layers"]){
			for (let i = 0; i < network["config"]["layers"].length; i++){
				let data = network["config"]["layers"][i];
				
				for (let j = 0; j < data["inputs"].length; j++){
					let linkingNodeKey = data["inputs"][j]
					let outPort = nodeMap[linkingNodeKey]["outPort"];
					let inPort = nodeMap[data["id"]]["inPort"];
					let link = outPort.link(inPort);
					links.push(link)
					console.log("link", link);
				}
			}
		}
		
		let models = model.addAll(...modelNodes, ...links);
		// add a selection listener to each
		models.forEach(item => {
			item.addListener({
				positionChanged: this.updateNodePosition
			});
			item.addListener({
				selectionChanged: this.didSelect
			});
		});



			
		//add a prevent zoom listener
		let preventZoom = function(z){
			z.entity.zoom = 100;
		}

		model.addListener({
			zoomUpdated: preventZoom
		})
		model.addListener({
			linksUpdated: this.linksUpdated
		})
		model.addListener({
			nodesUpdated: this.nodesUpdated
		})
		model.addListener({
			gridUpdated: this.gridUpdated
		})

		// 7) load model into engine
		engine.setDiagramModel(model);

		this.setState({id2nodeModel:id2nodeModel});

		return {engine, model}
	}

	

	saveNetwork(){
		let {model, id2nodeModel, network} = this.state;
		let {saveCallback, name} = this.props;

		let nodes = model.getNodes();
		let links = model.getLinks();
		console.log("saveNetwork Nodes", nodes);
		
		let new_network = {
			layers:[],
			map:[],
		}

		if(name){
			new_network["name"] = name
		}

		//if we have an existing network, add the id
		if (network) {
			new_network["id"] = network.id
		}

		for (const key in nodes){
			let x = id2nodeModel[key]
			let n = nodes[key];

			x["id"] = key;
			x["inputs"] = [];
			x["outputs"] = [];
			x["x"] = n["x"];
			x["y"] = n["y"];

			//assign defaults to kwargs / args
			if (x["args"]){
				for(let i = 0; i < x["args"].length; i++){
					if(!x["args"][i]["value"]){
						x["args"][i]["value"] = x["args"][i]["default"];
					}
				}
			}
			if (x["kwargs"]){
				for(let i = 0; i < x["kwargs"].length; i++){
					if(!x["kwargs"][i]["value"]){
						x["kwargs"][i]["value"] = x["kwargs"][i]["default"];
					}
				}
			}
			new_network.map[key] = x;
		}

		for (const key in links){
			
			let link = links[key];
			//console.log(link);
			let source = link.sourcePort.parent.id;
			let dest = link.targetPort.parent.id
			if (source && dest){
			  new_network.map[source]["outputs"].push(dest);
			  new_network.map[dest]["inputs"].push(source);
			}
		}

		//create our layer list syntax
		for (const key in new_network.map){
			new_network.layers.push(new_network.map[key])
		}

		
		
		console.log("save_network", new_network);
		saveCallback({"config":new_network})
		
	}

	updateCallback(newValue, itemKey, objectId){
		let {id2nodeModel, layerSchema} = this.state;
		let x = id2nodeModel[objectId];

		if (!x){
			console.log("Error unable to find node model", objectId);
		}
		if (x["args"]){
			for (let i = 0; i < x["args"].length; i++){
				let arg = x["args"][i];
				if (arg["key"] === itemKey){
					if (arg["type"] === "int" || arg["type"] === "float"){
						newValue = parseFloat(newValue)
					}
					x["args"][i]["value"] = newValue
				}
			}
		}
		if (x["kwargs"]){
			for (let i = 0; i < x["kwargs"].length; i++){
				let kwarg = x["kwargs"][i];
				if (kwarg["key"] === itemKey){
					if (kwarg["type"] === "int" || kwarg["type"] === "float"){
						newValue = parseFloat(newValue)
					}
					x["kwargs"][i]["value"] = newValue
				}
			}
		}
		id2nodeModel[objectId] = x;
		this.setState({id2nodeModel:id2nodeModel});
		console.log(x);

	}

	/* Sidebar Options */
	mapItemToInput(item, i, objectId, callback){
		let defaultTitle = item.key.charAt(0).toUpperCase() + item.key.slice(1)
		if(item.type === "float"){
			return (<NumberInput 
				key={i}
				objectId={objectId}
				itemKey={item.key}
				title={defaultTitle} 
				defaultValue={item.default}
				min={item.min_value}
				max={item.max_value}
				step={0.01}
				description={item.notes}
				updateCallback={callback}
			/> )

		} else if (item.type === "int") {
			return (<NumberInput 
				key={i}
				objectId={objectId}
				itemKey={item.key}
				title={defaultTitle} 
				defaultValue={item.default}
				min={item.min_value}
				max={item.max_value}
				step={1.0}
				description={item.notes}
				updateCallback={callback}
			/> )
		} else if (item.type === "set" || item.type === "superset" ){
			return (<OptionsInput 
				key={i}
				
				objectId={objectId}
				itemKey={item.key}
				title={defaultTitle} 
				defaultValue={item.default}
				options={item.options}
				description={item.notes}
				updateCallback={callback}
			/> )
			
		} else {
			console.log("item type not implemented", item.type);
			return (<StringInput 
				key={i}
				objectId={objectId}
				itemKey={item.key}
				title={defaultTitle} 
				defaultValue={item.default}
				min={item.min_value}
				max={item.max_value}
				step={1.0}
				description={item.notes}
				updateCallback={callback}
			/>)
		}
	}


	render(){

		const {
			engine, 
			model, 
			selectedNode, 
			id2nodeModel,
			layerSchema,
		} = this.state;

		const {
			dateLastSaved,
		} = this.props;
		var lastSaved = "";
		if (dateLastSaved){
			lastSaved = (<p className="text-brn d-inline animated fadeInLeft">Saved: {dateLastSaved}</p>);
		}

		if (!engine){
			return (
				<div>Loading</div>
			)
		}		
		
		let selectedMenu = <div></div>
		if (selectedNode){
			
			let n = id2nodeModel[selectedNode.entity.id];
						
			if (n){

				let layerOptions = layerSchema[n.type]
				
				if (layerOptions){
					let args = [];
					if (layerOptions["args"]){
						for (let i = 0; i < layerOptions["args"].length; i++){
							let item = layerOptions["args"][i]
							let component = this.mapItemToInput(item, i, selectedNode.entity.id, this.updateCallback);
							args.push(component);
						}
					}

					let kwargs = [];
					if (layerOptions["kwargs"]){
						for (let i = 0; i < layerOptions["kwargs"].length; i++){
							let item = layerOptions["kwargs"][i]
							let component = this.mapItemToInput(item, i, selectedNode.entity.id, this.updateCallback);
							kwargs.push(component);
						}
						
					}
					selectedMenu = (
					<div class="card mb-3 bg-primary">

						  <h3 class="card-header">{n.title}</h3>
						  <div class="card-body">
						  	{args}
						    <p class="card-text"></p>
						    {kwargs}
						  </div>
						  <div class="card-footer text-muted">
						  </div>
						</div>
						
					)	
				}

								
			}
		}
		
		return (
			
			<div class="row zeromargin" >

				<div className="col-sm-9 bg-primary border-bottom-brn border-right-brn zeropad">
					<button class="btn btn-primary" onClick={this.resetGrid}><i class="far fa-dot-circle" ></i> Center</button>
					<button class="btn btn-primary d-inline" onClick={this.saveNetwork}><i class="fas fa-save" ></i> Save </button>
					{lastSaved}
				</div>
				<div className="col-sm-3 bg-primary border-bottom-brn zeropad">
					<button class="btn btn-primary" onClick={this.trainNetwork}><i class="fas fa-cog" ></i> Train</button>
					
				</div>
				

				<div className="col-sm-9 zeropad">
					
					<div 
						className="diagram-layer border-right-brn"
						onDrop={event => {
							let data = JSON.parse(event.dataTransfer.getData("storm-diagram-node"));
							this.handleDrop(event, data);
						}}
						onDragOver={event => {event.preventDefault();}}
						
					>
					
						<SRD.DiagramWidget
							onClick={this.handleClick} 
							zoom={100}
							diagramEngine={engine} 
							style={{minHeight:"400px"}} />
					</div>
				</div>

				<div class="col-sm-3 bg-primary" style={{padding:"0px"}}>
					{selectedMenu}
				</div>

				
			</div>
			
		)
	}
}


export default NetworkDesigner;

